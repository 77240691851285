export let confEnv = process.env;
export const environmentType = process.env.NODE_ENV;
export let confProj = process.env.REACT_APP_PROJ || "prod";
export let deploymentType = process.env.REACT_APP_DEPLOYMENT_TYPE || "cloud";

setupConfig();

// These are keyed the same as the aliases in .firebasrc , which firebase calls a "project".
const projectConfigs = {
    "dev": {
        "firebaseConfig": {
            apiKey: "AIzaSyAJg2nu00eKHxFaGiX3ftIPLpI1bxPPNSo",
            authDomain: "dev.syncretic.cc",
            projectId: "syncretic-prototype-01-dev",
            storageBucket: "syncretic-prototype-01-dev.appspot.com",
            messagingSenderId: "455859974342",
            appId: "1:455859974342:web:e3222c277b22a17ca6a7c7"
        },
        "cloudRunURLs": {
            "runLanguageModel": "https://runlanguagemodel-25oirda6sq-uc.a.run.app/runlanguagemodel",
            "runAgent": "https://runagent-25oirda6sq-uc.a.run.app/",
        },
        "algoliaConfig": {
            local: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "691a3087ebd860db4b468e62b967e423",
                allIndexName: "local_ALL_INDEX",
                entityIndexName: "local_ENTITY_INDEX",
                entityRecordIndexName: "local_ENTITY_RECORDS_INDEX",
            },
            cloud: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "691a3087ebd860db4b468e62b967e423",
                allIndexName: "dev_ALL_INDEX",
                entityIndexName: "dev_ENTITY_INDEX",
                entityRecordIndexName: "dev_ENTITY_RECORDS_INDEX",
            }
        }
    },
    "dev01": {
        "firebaseConfig": {
            apiKey: "AIzaSyAJg2nu00eKHxFaGiX3ftIPLpI1bxPPNSo",
            authDomain: "syncretic-prototype-01-dev.web.app",
            projectId: "syncretic-prototype-01-dev",
            storageBucket: "syncretic-prototype-01-dev.appspot.com",
            messagingSenderId: "455859974342",
            appId: "1:455859974342:web:e3222c277b22a17ca6a7c7"
        },
        "cloudRunURLs": {
            "runLanguageModel": "https://runlanguagemodel-25oirda6sq-uc.a.run.app/runlanguagemodel",
            "runAgent": "https://runagent-25oirda6sq-uc.a.run.app/",
        },
        "algoliaConfig": {
            local: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "691a3087ebd860db4b468e62b967e423",
                allIndexName: "local_ALL_INDEX",
                entityIndexName: "local_ENTITY_INDEX",
                entityRecordIndexName: "local_ENTITY_RECORDS_INDEX",
            },
            cloud: {
                allIndexName: "dev_ALL_INDEX",
                entityIndexName: "dev_ENTITY_INDEX",
                entityRecordIndexName: "dev_ENTITY_RECORDS_INDEX",
            }
        }
    },
    "prod": {
        "firebaseConfig": {
            apiKey: "AIzaSyC_8lGDjHSKFqSqaYGvU5cP4JwZ_xnPTAs",
            authDomain: "prototype.syncretic.cc",
            projectId: "syncretic-prototype-01",
            storageBucket: "syncretic-prototype-01.appspot.com",
            messagingSenderId: "317167905555",
            appId: "1:317167905555:web:5df35becbdd44c176f48e6",
        },
        "cloudRunURLs": {
            "runLanguageModel": "https://runlanguagemodel-2laxbn7j7a-uc.a.run.app/runlanguagemodel",
            "runAgent": "",
        },
        "algoliaConfig": {
            local: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "691a3087ebd860db4b468e62b967e423",
                allIndexName: "local_ALL_INDEX",
                entityIndexName: "local_ENTITY_INDEX",
                entityRecordIndexName: "local_ENTITY_RECORDS_INDEX",
            },
            cloud: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "073b2aa9884b5d0afd9a8aa28431050a",
                allIndexName: "prod_ALL_INDEX",
                entityIndexName: "prod_ENTITY_INDEX",
                entityRecordIndexName: "prod_ENTITY_RECORDS_INDEX",
            }
        }
    },
};

// We make confEnv from process.env.  If 'confProj' is 'thisproj', then
// for each REACT_APP_PROJ_thisproj_xxxx=yy a confEnv "environment
// variable" is added in as xxxx=yy .  The original REACT_APP_PROJ_*
// environment variables are removed.

function setupConfig() {
    confEnv = process.env;
    const rprefix = "REACT_APP_PROJ_";
    for (const v in process.env) {
        if (v.match("^" + rprefix)) {
            delete confEnv[v];
        }
        const m = v.match("^" + rprefix + confProj.toUpperCase() + "_(.*$)");
        if (m) {
            confEnv[m[1]] = process.env[v];
        }
    }
}


if (!confProj || !projectConfigs[confProj] || !projectConfigs[confProj].firebaseConfig) {
    throw Error(`Cannot find config for project ${confProj}`);
}

export const firebaseConfig = projectConfigs[confProj].firebaseConfig;
export const stripeConfig = projectConfigs[confProj].stripeConfig;
export const cloudRunURLs = projectConfigs[confProj].cloudRunURLs;
export const algoliaConfig = projectConfigs[confProj].algoliaConfig[deploymentType];


console.log(`Project: ${confProj}\nid: ${firebaseConfig['projectId']}\nenvironmentType: ${environmentType}\ndeploymentType: ${deploymentType}`);

if (confEnv['REACT_APP_VERSION']) {
    console.log(`===== Version: ${confEnv['REACT_APP_VERSION']}`);
}